import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "ptp-tolerance pt-2 px-5" }, [_c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("h2", { staticClass: "ptp-tolerance__title mb-0 pb-4" }, [_vm._v("PTP Tolerance")]), _c("a-table", { staticClass: "ptp-tolerance__table", attrs: { "columns": _vm.columns, "data-source": _vm.ptpTolerance, "pagination": false, "row-key": "id", "bordered": "", "loading": _vm.isLoading }, scopedSlots: _vm._u([{ key: "min", fn: function(text, record) {
      return [record.editable ? _c("text-input", { attrs: { "value": text, "label": "Min", "show-label": false, "rules": "required|numeric" + (_vm.max ? "|max_value:" + _vm.max : ""), "form-item": "" }, on: { "change": function($event) {
        return _vm.onEdit("min", $event);
      } } }) : [_vm._v(" " + _vm._s(text) + "% ")]];
    } }, { key: "max", fn: function(text, record) {
      return [record.editable ? _c("text-input", { attrs: { "value": text, "label": "Max", "show-label": false, "rules": "required|numeric" + (_vm.min ? "|min_value:" + _vm.min : ""), "form-item": "" }, on: { "change": function($event) {
        return _vm.onEdit("max", $event);
      } } }) : [_vm._v(" " + _vm._s(text) + "% ")]];
    } }, { key: "action", fn: function(text, record) {
      return [record.editable ? _c("div", { staticStyle: { "marginBottom": "25px" } }, [_c("a-tooltip", { attrs: { "destroy-tooltip-on-hide": true }, scopedSlots: _vm._u([{ key: "title", fn: function() {
        return [_vm._v("Save")];
      }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "check", "size": "small", "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.onSave);
      } } })], 1), _c("a-tooltip", { attrs: { "destroy-tooltip-on-hide": true }, scopedSlots: _vm._u([{ key: "title", fn: function() {
        return [_vm._v("Cancel")];
      }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "close", "size": "small", "type": "button" }, on: { "click": function($event) {
        return _vm.onCancel(record.id);
      } } })], 1)], 1) : _c("div", [_c("a-button", { attrs: { "disabled": _vm.editingKey !== "", "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
        return _vm.onEditClick(record.id);
      } } })], 1)];
    } }], null, true) })];
  } }]) })], 1);
};
var staticRenderFns$1 = [];
var PtpTolerance_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => ".ptp-tolerance{background:white}.ptp-tolerance__table{max-width:600px}.ptp-tolerance .ant-table-tbody>tr>td{border-bottom:0px}\n")();
const __vue2_script$1 = {
  name: "PtpTolerance",
  inject: ["apiUrl", "crud"],
  data() {
    return {
      ptpTolerance: null,
      cachePtpTolerance: null,
      cacheRecord: {},
      editingKey: "",
      isLoading: false,
      min: null,
      max: null,
      columns: [
        {
          title: "Min",
          dataIndex: "min",
          scopedSlots: { customRender: "min" }
        },
        {
          title: "Max",
          dataIndex: "max",
          scopedSlots: { customRender: "max" }
        },
        {
          title: "Action",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          width: "80px",
          align: "center",
          ellipsis: true
        }
      ]
    };
  },
  watch: {
    ptpTolerance(newVal) {
      this.cacheRecord = newVal[0];
    }
  },
  created() {
    this.getPtpTolerance();
  },
  methods: {
    parse(value) {
      return value + "%";
    },
    async getPtpTolerance() {
      this.isLoading = true;
      await this.crud.fetchList();
      this.ptpTolerance = this.crud.getList();
      this.cachePtpTolerance = this.ptpTolerance.map((item) => ({ ...item }));
      this.storeValues(this.ptpTolerance[0]);
      this.isLoading = false;
    },
    storeValue(resourceKey, value) {
      this.crud.updateEntity("editForm", { resourceKey, value });
    },
    storeValues(record) {
      Object.entries(record).forEach(([resourceKey, value]) => {
        this.crud.updateEntity("editForm", { resourceKey, value });
      });
    },
    onEditClick(id) {
      const newData = [...this.ptpTolerance];
      const target = newData.filter((item) => id === item.id)[0];
      this.editingKey = id;
      if (target) {
        target.editable = true;
        this.ptpTolerance = newData;
      }
    },
    onEdit(key, value) {
      this.storeValue(key, value);
      if (key === "min") {
        this.max = this.cacheRecord.max;
        this.min = null;
      } else {
        this.min = this.cacheRecord.min;
        this.max = null;
      }
    },
    onSave() {
      this.crud.submitEntity("replace", `${this.apiUrl}/line-performance/ptp-tolerance/${this.editingKey}`).then(() => {
        this.getPtpTolerance();
        this.editingKey = "";
      });
    },
    onCancel(id) {
      const newData = [...this.ptpTolerance];
      const target = newData.filter((item) => id === item.id)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(target, this.cachePtpTolerance.filter((item) => id === item.id)[0]);
        delete target.editable;
        this.ptpTolerance = newData;
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var PtpTolerance = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.menuItems } }), _c("resource", { attrs: { "name": "line-performance.ptp-tolerance", "api-url": _vm.apiUrl } }, [_c("ptp-tolerance")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { PtpTolerance },
  data() {
    return {
      apiUrl,
      menuItems: [
        {
          key: "ctp",
          title: "CTP",
          path: ""
        },
        {
          key: "master-files",
          title: "Master Files",
          path: ""
        },
        {
          key: "ptp-tolerance",
          title: "PTP Tolerance",
          path: "/line-performance/ptp-tolerance"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
